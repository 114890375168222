<template>
  <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="科目余额表" name="1">
      <subjecthis ref="subjectBalance" tongbu="true" @success="tiaozhuan" ></subjecthis>
    </el-tab-pane>
    <el-tab-pane label="核算项目余额表" name="2" lazy>
      <fzhsSubjectBalance ref="fzhsSubjectBalance"></fzhsSubjectBalance>
    </el-tab-pane>
    <el-tab-pane label="凭证汇总" name="3" lazy>
      <allvoucherhis ref="proofCollect"></allvoucherhis>
    </el-tab-pane>
    <el-tab-pane label="总账" name="4" lazy>
      <allaccounthis ref="generalLedger"></allaccounthis>
    </el-tab-pane>
    <el-tab-pane label="明细账" name="5" lazy>
      <detailaccounthis ref="detailAccount"></detailaccounthis>
    </el-tab-pane>
    <el-tab-pane label="核算项目明细账" name="6" lazy>
      <fzhsDetailAccount ref="fzhsDetailAccount" ></fzhsDetailAccount>
    </el-tab-pane>
    <el-tab-pane label="多栏账" name="8" lazy>
        <multicolumnAccount ref="multicolumnAccount" ></multicolumnAccount>
      </el-tab-pane>
    <el-tab-pane label="日记账" name="7" lazy>
      <journalhis ref="fee"></journalhis>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import journalhis from "./components/dayBook.vue"

import subjecthis from "./components/subjectBalance.vue"
import fzhsSubjectBalance from "./components/fzhsSubjectBalance.vue"
import allaccounthis from "./components/generalLedger.vue"
import detailaccounthis from "./components/detailAccount.vue"
import fzhsDetailAccount from "./components/fzhsDetailAccount.vue"
import allvoucherhis from "./components/proofCollect.vue"
import multicolumnAccount from "./components/multicolumnAccount.vue"
export default {
  name: "history",
  components:{
    subjecthis,
    fzhsSubjectBalance,
    allaccounthis,
    detailaccounthis,
    allvoucherhis,
    fzhsDetailAccount,
    journalhis,
    multicolumnAccount
  },
  data() {
    return{
      activeName:'1'
    }
  },
  mounted(){
    if(this.$route.params.subjectId){
      this.tiaozhuan(this.$route.params)
    }
  },
  methods:{
    handleClick(tab,e){
      if(tab.props.label == '科目余额表'){
        // this.$refs.subjectBalance.getList()
      }else if(tab.props.label == '凭证汇总'){
        // this.$refs.proofCollect.getList()
      }else if(tab.props.label == '总账'){
        // this.$refs.generalLedger.getList()
      }else if(tab.props.label == '明细账'){
        // this.$refs.detailAccount.getList(e)
      }else if(tab.props.label == '核算项目余额表'){
        // this.$refs.fzhsSubjectBalance.getList()
      }else if(tab.props.label == '核算项目明细账'){
        // this.$refs.fzhsDetailAccount.getList()
      }else if(tab.props.label == '多栏账'){
        setTimeout(() => {
          this.$refs.multicolumnAccount.getList()
        })
      }
    },
    tiaozhuan(e){
      let param = {
        props:{
          label:'明细账',
        },
      }
      this.activeName = '5'
      this.handleClick(param)
      this.$refs.detailAccount.getList(e)
    }
  }
}
</script>

<style lang="scss" scoped>
/* .el-tabs__item {
  width: 60px;
  background: linear-gradient(19deg, transparent 15px, #58a 0);
}  */
.el-tabs__item{
  width: 50px;
  margin: 0 auto !important;
  height: 60px;
  line-height: 20px;
  writing-mode: vertical-lr;/*从左向右 从右向左是 writing-mode: vertical-rl;*/  
  writing-mode: tb-lr;/*IE浏览器的从左向右 从右向左是 writing-mode: tb-rl；*/
  border: 0px !important;
}
.el-tabs__item.is-active {
  color: white !important;
}
.is-active {
  background: linear-gradient(24deg, transparent 20px, #67caf9 0);
  background-color: #f5f7fa !important;
  color: white !important;
  // background: linear-gradient(-135deg, transparent 15px, var(--themeColor,#17a2b8) 0) top right, linear-gradient(45deg, transparent 15px, var(--themeColor,#17a2b8) 0) bottom left; background-size: 50%; background-repeat: no-repeat;
}
</style>