<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-input placeholder="请输入标题" v-model="listQuery.titleDesc" style="width:200px" size="small" clearable @keyup.enter="getList" />
        <el-button size="small" type="primary" icon="Search" @click="getList" style="margin-right: 5px;">搜索</el-button>
        <search @success="getList" @cancel="cancel" name="高级筛选">
          <el-form
            style="width: 300px;"
            label-width="80px"
          >
            <el-form-item label="广告类型"> 
              <el-select
                v-model="listQuery.markType"
                placeholder="请选择广告类型"
                filterable
                clearable
                size="small"
                style="width: 215px;margin: 0;"
              >
                <el-option
                  v-for="item in adManageList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="启用状态"> 
              <el-select
                v-model="listQuery.status"
                placeholder="请选择广告类型"
                filterable
                size="small"
                style="width: 215px;margin: 0;"
              >
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="添加时间："> 
              <el-date-picker
                v-model="listQuery.timeBegin"
                type="date"
                placeholder="开始时间"
                value-format="YYYY-MM-DD"
                size="small"
                style="width: 100px"
              /> &nbsp;-&nbsp;
              <el-date-picker
                v-model="listQuery.timeEnd"
                type="date"
                placeholder="结束时间"
                value-format="YYYY-MM-DD"
                size="small"
                style="width: 100px"
              />
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div style="height: 30px;display: flex;align-items: center;">
        <el-button
          @click="add"
          type="primary"
          size="small"
          >添加</el-button
        >
      </div>
    </div>
    <el-table
      :data="list"
      border
      style="width: 100%"
      v-loading="loading"
      :height="contentStyleObj"
      @selection-change="handleSelectionChange"
      stripe
    >
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" type="selection" width="55"> </el-table-column>
      <el-table-column prop="titleDesc" label="标题" width="400" fixed>
      </el-table-column>
      <el-table-column prop="markType" label="广告类型" width="160"  align="center" fixed>
      </el-table-column>
      <el-table-column prop="content"  label="广告内容" align="center" width="450">
        <template #default="{ row }">
          <span v-if="row.markType == '文字'">
            {{ row.content }}
          </span>
          <span v-else>
            <el-image style="width: 30px; height: 30px" :src="row.content" :preview-src-list="[row.content]"
              preview-teleported="true"></el-image>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="status"  label="是否启用" align="center" width="150">
        <template #default="{ row }">
          <span v-if="row.status === 1">
            <el-tag type="success">是</el-tag>
          </span>
          <span v-else>
            <el-tag type="danger">否</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="created"  label="添加时间" align="center" width="250">
      </el-table-column>
      <el-table-column prop="count" label="操作" align="center" min-width="150">
        <template #default="{ row }">
          <el-button
            size="small"
            type="primary"
            link
            @click="edit(row)"
            >编辑</el-button>
          <el-button
            size="small"
            type="primary"
            link
            @click="del(row.id)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="pagination">
    <qzf-pagination
      v-show="total > 0"
      :total="total"
      v-model:page="listQuery.page"
      v-model:limit="listQuery.pageSize"
      @pagination="getList"
    />
  </div>
  <el-dialog
    :close-on-click-modal="false"
    destroy-on-close
    :title="type == 'edit'? '编辑广告' : '添加广告'"
    v-model="addShow"
    width="26%"
    v-if="addShow"
  >
    <el-form size="small" :model="form" :rules="rules" ref="ruleFormRef" label-position="right"   label-width="80px">
      <el-form-item label="标题" prop="titleDesc">
        <el-input v-model="form.titleDesc" size="small"  placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="广告类型" prop="markType">
        <el-radio-group v-model="form.markType" @change="marChange">
          <el-radio  :label="'文字'" size="small">文字</el-radio>
          <el-radio  :label="'图片'" size="small">图片</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="广告内容" prop="content">
        <div style="width: 100%;">
          <FileUploader v-if="form.markType == '图片'" @change="updateFileList" :accept="'.jpg, .png, .jpeg'" :initialFileList="form.fileList" />
          <el-input
            v-model="form.content"
            :rows="10"
            maxlength="200"
            show-word-limit
            type="textarea"
            placeholder="请输入广告内容"
            v-else
          />
        </div>
        <div style="font-size: 12px;color: red;" v-if="form.markType == '图片'">
          图片尺寸：520px*680px或者等比。图片大小：200kb。
        </div>
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="1" size="small">是</el-radio>
          <el-radio :label="0" size="small">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="弹窗频率" prop="rule">
        <el-radio-group v-model="form.rule" :disabled="type == 'edit'">
          <el-radio :label="1" size="small">仅首次打开显示</el-radio>
          <el-radio :label="2" size="small">每天显示一次</el-radio>
          <el-radio :label="3" size="small">每次打开显示一次</el-radio>
          <el-radio :label="4" size="small">每3天显示一次</el-radio>
          <el-radio :label="5" size="small">每7天显示一次</el-radio>
          <el-radio :label="6" size="small">每30天显示一次</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="addShow = false"
          >取 消</el-button
        >
        <el-button
          size="small"
          type="primary"
          @click="save"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, onMounted,getCurrentInstance } from "vue";
import { ElMessage,ElMessageBox } from "element-plus";
import { xcxAdvertisementinList,saveAdvertisement,delAdvertisement } from "@/api/company"
import FileUploader from "@/components/uploadImg/FileUploader.vue";
const { proxy } = getCurrentInstance();
const ruleFormRef = ref();
const loading = ref(false)
const addShow = ref(false)
const saveLoading = ref(false)
const total = ref(0)
const list = ref([])
const sels = ref([])
const ids = ref([])
const form = ref({})
const type = ref('')
const rules = ref({
  titleDesc: [{ required: true, message: "请输入标题", trigger: "blur" }],
  content: [{ required: true, message: "请输入广告内容", trigger: "blur" }],
  status: [{ required: true, message: "请选择是否启用", trigger: "change" }],
  rule: [{ required: true, message: "请选择弹窗规则", trigger: "change" }],
  markType: [{ required: true, message: "请选择广告类型", trigger: "change" }],
});
const listQuery = ref({
  page: 1,
  limit: 20,
  titleDesc:'',
  markType:'',
  timeBegin:'',
  timeEnd:'',
  status: 99,
})
const adManageList = ref([
  {
    value: '',
    label: '全部',
  },
  {
    value: '文字',
    label: '文字',
  },
  {
    value: '图片',
    label: '图片',
  },
]);
const statusList = ref([
  {
    value: 99,
    label: '全部',
  },
  {
    value: 1,
    label: '是',
  },
  {
    value: 0,
    label: '否',
  },
]);

const contentStyleObj = ref({});//列表高度

onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(250);
  getList();
});
const getList = () => {
  loading.value = true;
  xcxAdvertisementinList({
        ...listQuery.value,
      }).then((res) =>{
    console.log(res);
    loading.value = false;
    if(res.data.msg == 'success'){
      list.value = res.data.data.list
      total.value = res.data.data.total
      if(list.value.length > 0){
        list.value.forEach((i) =>{
          i.created = i.createdAt.split('T')[0]
        })
      }
    }
  })
};
const add = () =>{
  reset()
  addShow.value = true
  type.value = 'add'
  form.value.markType = '图片'
}
const marChange = () =>{
  form.value.content = ''
}
const edit = (row) =>{
  type.value = 'edit'
  form.value = Object.assign({},row);
  if(row.markType == '图片'){
    if (form.value.content) {
      let arr = [form.value.content]
      form.value.fileList = arr.map(v => {
        const ext = v.split(".").pop();
        return {
          name: v.split("/").pop(),
          url: v,
          type: ext,
        }
      });
    }
  }
  addShow.value = true
}
const del = (val) =>{
  ElMessageBox.confirm(
    '确定要删除广告',
    '删除',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: '确定',
    }
  )
  .then(() => {
    delAdvertisement({id:val}).then((res) => {
      if(res.data.msg =='success'){
        ElMessage.success("删除成功");
        getList();
      }
    })
  })
  .catch(() => {
  })
}
const handleSelectionChange = (e) =>{
  sels.value = e;
  ids.value = sels.value.map((v) => v.id)
}
//上传合同附件
const updateFileList = (e) => {
  form.value.content = e[0].url;
  
};
const reset = () =>{
  form.value = {
    titleDesc:'',
    markType:'',
    timeBegin:'',
    timeEnd:'',
    status: null,
    content:'',
  }
}
const save = () =>{
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      if(form.value.markType == '图片'){
        if(!form.value.content){
          ElMessage.error('请上传图片');
          return;
        }
      }
      saveLoading.value = true;
      saveAdvertisement(form.value).then((res) =>{
        console.log(res);
        saveLoading.value = false;
        if(res.data.msg == 'success'){
          ElMessage.success('保存成功');
          addShow.value = false;
          getList()
        }
      })
    }
  });
}
const cancel = () =>{
  listQuery.value ={
    page: 1,
    limit: 20,
    titleDesc:'',
    markType:'',
    timeBegin:'',
    timeEnd:'',
    status: 99,
  }
  getList()
}


</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.w250{
  width: 250px;
}
.pagination {
  margin-top: 16px;
  float: right;
}
</style>
